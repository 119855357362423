<template>
  <div class="referrals">
    <div class="filters">
      <div class="search">
        <label for="search">Cerca</label>
        <input
          id="search"
          v-model="filters.search"
          type="text"
          name=""
          placeholder="Azienda, Stato, Utente"
        >
      </div>

      <div class="lens">
        <font-awesome-icon icon="magnifying-glass" class="fa-lg" />
      </div>

      <div v-if="permissions.canUse('DISCOUNT_CODE_CREATE')" class="btn-confirm">
        <material-button text="Nuovo" type="info" @click="openNewCoupon()" />
      </div>

      <!-- <div class="btn-confirm">
        <material-button text="Revoca" type="danger" :disabled="!getFilteredRows().find((f) => f.selected)" @click="btnConfirmOnClick()" />
        <span v-show="getFilteredRows().filter((f) => f.selected).length" class="counter-badge">
          {{ getFilteredRows().filter((f) => f.selected).length }}
        </span>
      </div> -->
    </div>

    <div class="table">
      <table id="mainTable" cellspacing="0">
        <thead>
          <tr>
            <th
              v-for="header of mainHeaders"
              :key="header"
              class="orderable"
              @click="changeOrder(header)"
            >
              <div>
                <span>{{ header }}</span>
                <font-awesome-icon
                  v-if="filters.order.field == header"
                  :icon="
                    filters.order.asc ? 'arrow-down-a-z' : 'arrow-down-z-a'
                  "
                />
              </div>
            </th>
          </tr>
        </thead>

        <tbody class="table-body">
          <slot
            v-for="(document, index) in getFilteredRows"
            :key="'main_' + index"
          >
            <tr
              class="cursor-pointer"
              @click="toggleMasterDetail('main_' + index)"
            >
              <td>
                {{ document.codeName }}
              </td>
              <td>{{ dayjs(document.mostRecentCouponDate).format('DD/MM/YYYY') }}</td>
              <td>
                <div class="dot-border">
                  {{ document.total }}
                </div>
              </td>
              <td>
                <div class="dot-border" :class="'dot-color0'">
                  {{ document.notUsed }}
                </div>
              </td>
              <td>
                <div class="dot-border" :class="'dot-color1'">
                  {{ document.used }}
                </div>
              </td>
              <td>
                <div class="dot-border" :class="'dot-color2'">
                  {{ document.expired }}
                </div>
              </td>
              <td class="chevron" style="width: 50px">
                <svg
                  :class="{
                    rotate: collapsiblePanelOpened === 'main_' + index,
                  }"
                  style="width: 24px; height: 24px"
                  viewBox="0 0 24 24"
                >
                  <path
                    fill="currentColor"
                    d="M8.59,16.58L13.17,12L8.59,7.41L10,6L16,12L10,18L8.59,16.58Z"
                  />
                </svg>
              </td>
            </tr>

            <tr
              v-if="collapsiblePanelOpened === 'main_' + index"
              class="collapsible-content"
            >
              <td
                colspan="7"
                class="content-inner"
                style="padding: 0 !important"
              >
                <div class="table-container">
                  <table class="content-table history">
                    <thead>
                      <tr>
                        <th
                          v-for="header of headers"
                          :key="header"
                          class="orderable"
                          @click="changeOrderInside(header)"
                        >
                          <div class="">
                            <span>{{ header }}</span>
                            <font-awesome-icon
                              v-if="filters.order.fieldInside == header"
                              :icon="
                                filters.order.ascInside
                                  ? 'arrow-down-a-z'
                                  : 'arrow-down-z-a'
                              "
                            />
                          </div>
                        </th>
                      </tr>
                    </thead>
                    <tr
                      v-for="coupon in document.coupons"
                      :key="coupon.id"
                      class="history-tr-content"
                    >
                      <td>{{ coupon.code }}</td>
                      <td>
                        {{ dayjs(coupon.generationDate).format("DD/MM/YYYY") }}
                      </td>
                      <td>{{ coupon.amount }}</td>
                      <td>
                        <span
                          v-if="coupon.discountType === 0"
                        >Percentuale</span>
                        <span v-else>Euro</span>
                      </td>
                      <td>
                        <span class="dot" :class="'dot-color' + coupon.state" />
                        <span v-if="coupon.state === 0">Da utilizzare</span>
                        <span v-else-if="coupon.state === 1">Utilizzato</span>
                        <span v-else>Scaduto</span>
                      </td>
                      <td>
                        {{
                          dayjs(coupon.startValidityDate, "YYYY-MM-DD").format(
                            "DD/MM/YYYY"
                          )
                        }}
                        -
                        {{
                          dayjs(coupon.endValidityDate, "YYYY-MM-DD").format(
                            "DD/MM/YYYY"
                          )
                        }}
                      </td>
                      <td>{{ coupon.companyName }}</td>
                      <td>
                        {{ coupon.nameUsedBy }} {{ coupon.surnameUsedBy }}
                      </td>
                    </tr>
                  </table>
                </div>
              </td>
            </tr>
          </slot>
        </tbody>
      </table>
    </div>
  </div>
  <div v-show="newCouponCodeDialog" class="dialog">
    <div class="dialog-content">
      <form
        class="flex flex-column gap-1 custom-inputs"
        autocomplete="off"
        @submit.prevent
        @keydown.enter.prevent=""
      >
        <strong style="margin-bottom: 1rem">Nuovo codice sconto</strong>

        <div class="flex gap-1">
          <div class="flex-1">
            <input
              v-model="newCouponFormInputs.codeName"
              required
              class="w-full"
              type="text"
              placeholder="Nome sconto"
              @keydown.enter.prevent
            >
          </div>
          <div class="flex-1">
            <select
              v-model="newCouponFormInputs.numberOfCode"
              class="w-full"
              required
            >
              <option value="null">
                N˚ codici
              </option>
              <option
                v-for="option in [1, 2, 3, 4, 5, 6, 7, 8, 9, 10]"
                :key="option"
                :value="option"
              >
                {{ option }}
              </option>
            </select>
          </div>
        </div>

        <div class="flex gap-1">
          <div class="flex-1">
            <select
              v-model="newCouponFormInputs.discountType"
              class="w-full"
              required
              @change="discountTypeOnChange($event)"
            >
              <option value="1">
                €
              </option>
              <option value="0">
                %
              </option>
            </select>
          </div>
          <div class="flex-1">
            <select
              v-if="newCouponFormInputs.discountType == 1"
              v-model="newCouponFormInputs.amount"
              class="w-full"
              required
            >
              <option value="20">
                20 Euro
              </option>
              <option value="50">
                50 Euro
              </option>
              <option value="80">
                80 Euro
              </option>
              <option value="100">
                100 Euro
              </option>
            </select>
            <select
              v-else
              v-model="newCouponFormInputs.amount"
              class="w-full"
              required
            >
              <option value="50">
                50%
              </option>
              <option value="70">
                70%
              </option>
              <option value="80">
                80%
              </option>
              <option value="100">
                100%
              </option>
            </select>
          </div>
        </div>

        <div class="flex gap-1">
          <div>Validità</div>
          <div>
            <input
              v-model="newCouponFormInputs.startValidityDate"
              required
              type="date"
              placeholder="Inserisci data di inizio validità"
              @keydown.enter.prevent
            >
          </div>
          <div>-</div>
          <div>
            <input
              v-model="newCouponFormInputs.endValidityDate"
              required
              type="date"
              placeholder="Inserisci data di fine validità"
            >
          </div>
        </div>

        <div class="flex">
          <div class="flex-1">
            <input
              v-model="newCouponFormInputs.recipientEmail"
              type="text"
              placeholder="Email recruiter"
              class="w-full"
              @keydown.enter.prevent
            >
          </div>
        </div>

        <div class="flex">
          <div class="flex-1">
            <textarea
              v-model="newCouponFormInputs.messageEmail"
              placeholder="Aggiungi un messaggio"
              rows="3"
              class="w-full"
              style="resize: none"
              @keydown.enter.prevent
            />
          </div>
        </div>

        <div class="flex">
          <div class="flex-1 flex gap-1 flex-end">
            <material-button
              text="ANNULLA"
              type="light"
              @click="newCouponCodeDialog = false"
            />
            <material-button text="CREA" type="info" @click="newCoupon()" />
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import { reactive, toRefs, onMounted, computed } from 'vue'
import { sbapibackoffice } from '@/api'
import { spinnerStore } from '@/stores/spinner'
import { navbarStore } from '@/stores/navbar'
import dayjs from 'dayjs'
import { permissionsStore } from '@/stores/permissions'

export default {
  setup() {
    const spinner = spinnerStore()
    const navbar = navbarStore()
    const permissions = permissionsStore()

    const state = reactive({
      headers: [
        'Codice',
        'Data creazione',
        '%/€',
        'Tipologia',
        'Stato',
        'Validità codice',
        'Azienda',
        'Utilizzato da'
      ],
      mainHeaders: [
        'Nome Coupon',
        'Ultima Creazione',
        'Totale',
        'Da Utilizzare',
        'Utilizzati',
        'Scaduti',
        ''
      ],
      filters: {
        order: {
          field: 'Ultima Creazione',
          asc: false,
          fieldInside: 'Data creazione',
          ascInside: true
        },
        search: []
      },
      documents: [],
      newCouponCodeDialog: false,
      dayjs,
      newCouponFormInputs: {
        codeName: null,
        numberOfCode: null,
        discountType: 1, // 0 precentage; 1 euro
        amount: '20',
        startValidityDate: dayjs(),
        endValidityDate: null,
        recipientEmail: null,
        messageEmail: null
      },
      collapsiblePanelOpened: null
    })

    onMounted(() => {
      navbar.title = 'Codici sconto'
      getCoupons()
    })

    const toggleMasterDetail = (value) => {
      if (state.collapsiblePanelOpened === value)
        state.collapsiblePanelOpened = null
      else {
        state.collapsiblePanelOpened = value
      }
    }

    /**
     * Set the order condition for the table
     * @param {number} orderBy - the column to order by
     */
    const changeOrder = (orderBy) => {
      if (state.filters.order.field == orderBy) {
        state.filters.order.asc = !state.filters.order.asc
      } else {
        state.filters.order.field = orderBy
        state.filters.order.asc = true
      }
    }

    const getMostRecentCouponDate = (coupons) => {
      // const dates = coupons.map(c => c.generationDate)
      return new Date(Math.max(...coupons.map((e) => new Date(e.generationDate))))
    }

    const getFilteredRows = computed(() => {
      let arr = []
      if (state.filters.search != '') {
        arr = Object.values(state.documents).filter((d) => {
          return JSON.stringify(d)
            .toLowerCase()
            .includes(state.filters.search.toLowerCase())
        })
      } else {
        arr = state.documents
      }

      if (state.filters.order.fieldInside) {
        arr.forEach((record) => {
          record.coupons = record.coupons.sort((first, second) => {
            switch (state.filters.order.fieldInside) {
              case 'Codice':
                if (first.code < second.code) {
                  return state.filters.order.asc ? -1 : 1
                }
                if (first.code > second.code) {
                  return state.filters.order.asc ? 1 : -1
                }
                return 0
            }
          })
        })
      }

      return arr.sort((first, second) => {
        switch (state.filters.order.field) {
          case 'Nome Coupon':
            if (first.codeName < second.codeName) {
              return state.filters.order.asc ? -1 : 1
            }
            if (first.codeName > second.codeName) {
              return state.filters.order.asc ? 1 : -1
            }
            return 0
          case 'Ultima Creazione':
            if (dayjs(first.mostRecentCouponDate).isBefore(dayjs(second.mostRecentCouponDate))) {
              return state.filters.order.asc ? -1 : 1
            }
            if (dayjs(first.mostRecentCouponDate).isAfter(dayjs(second.mostRecentCouponDate))) {
              return state.filters.order.asc ? 1 : -1
            }
            return 0
          case 'Totale':
            if (Number(first.total) < Number(second.total)) {
              return state.filters.order.asc ? -1 : 1
            }
            if (Number(first.total) > Number(second.total)) {
              return state.filters.order.asc ? 1 : -1
            }
            return 0
          case 'Da Utilizzare':
            if (Number(first.notUsed) < Number(second.notUsed)) {
              return state.filters.order.asc ? -1 : 1
            }
            if (Number(first.notUsed) > Number(second.notUsed)) {
              return state.filters.order.asc ? 1 : -1
            }
            return 0
          case 'Utilizzati':
            if (Number(first.used) < Number(second.used)) {
              return state.filters.order.asc ? -1 : 1
            }
            if (Number(first.used) > Number(second.used)) {
              return state.filters.order.asc ? 1 : -1
            }
            return 0
          case 'Scaduti':
            if (Number(first.expired) < Number(second.expired)) {
              return state.filters.order.asc ? -1 : 1
            }
            if (Number(first.expired) > Number(second.expired)) {
              return state.filters.order.asc ? 1 : -1
            }
            return 0
        }
      })
    })

    const changeOrderInside = (orderBy) => {
      if (state.filters.order.fieldInside == orderBy) {
        state.filters.order.ascInside = !state.filters.order.ascInside
      } else {
        state.filters.order.fieldInside = orderBy
        state.filters.order.ascInside = true
      }
    }

    const toggleRowsSelection = (checked) => {
      state.documents.forEach((d) => {
        if (d.status == 0) d.selected = checked
      })
    }

    const openNewCoupon = () => {
      state.newCouponCodeDialog = true
      state.newCouponFormInputs = {
        codeName: null,
        numberOfCode: null,
        discountType: 1,
        amount: '20',
        startValidityDate: dayjs(),
        endValidityDate: null,
        recipientEmail: null,
        messageEmail: null
      }
    }

    const newCoupon = () => {
      spinner.show()
      sbapibackoffice
        .post('/referral/discountCode', state.newCouponFormInputs)
        .then(() => {
          getCoupons()
        })
        .catch((err) => {
          console.log(err)
        })
        .finally(spinner.hide)
    }

    const getCoupons = () => {
      spinner.show()
      sbapibackoffice
        .get('/referral/discountCode')
        .then((response) => {
          let ans = response.data.reduce((agg, curr) => {
            let found = agg.find((x) => x.codeName === curr.codeName)

            if (found) {
              found.coupons.push(curr)
            } else {
              agg.push({
                codeName: curr.codeName,
                coupons: [curr]
              })
            }
            return agg
          }, [])

          ans.forEach(element => {
            // foreach coupon name calculate the most recent date, and each state
            element.mostRecentCouponDate = getMostRecentCouponDate(element.coupons)
            element.total = element.coupons.length
            element.notUsed = element.coupons.filter((d) => d.state === 0).length
            element.used = element.coupons.filter((d) => d.state === 1).length
            element.expired = element.coupons.filter((d) => d.state === 2).length
          })

          state.documents = ans
        })
        .catch((err) => {
          console.log(err)
        })
        .finally(() => {
          spinner.hide()
          state.newCouponCodeDialog = false
        })
    }

    const discountTypeOnChange = (event) => {
      const selectedType = event.target.value
      if (selectedType == 1) {
        // Euro
        console.log(selectedType)
        state.newCouponFormInputs.amount = '20'
      } else {
        // Percentage
        state.newCouponFormInputs.amount = '50'
      }
    }

    return {
      ...toRefs(state),
      getFilteredRows,
      changeOrder,
      toggleRowsSelection,
      openNewCoupon,
      newCoupon,
      discountTypeOnChange,
      toggleMasterDetail,
      getMostRecentCouponDate,
      changeOrderInside,
      permissions
    }
  }
}
</script>

<style lang="scss" scoped>
@use "../assets/scss/referrals";
@use "../assets/scss/table";
@use "../assets/scss/dialog"; // general dialog styles
 
  tr th,
  tr td {
    text-align: left;
    width: calc(100% / 6);
    max-width: calc(100% / 6);
    // border:1px solid #dc3545;
  }
   tr th:first-child,
  tr td:first-child {
    padding-left:30px!important;
  }

.custom-inputs {
  select,
  textarea,
  input {
    padding: 5px;
    background-color: #eee;
    border-color: #eee;
    border-radius: 3px;
    outline: none;
    border: none;
  }
}

table .history-tr-header th:first-child,
table.history tr.history-tr-content td:first-child {
  padding-left: 1rem !important;
}
table .history-tr-header th:last-child,
table.history tr.history-tr-content td:last-child {
  padding-right: 1rem !important;
}
.rotate-icon {
  animation: rotation 8s infinite linear;
}
@keyframes rotation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}
.panel-table th,
.panel-table td {
  &:nth-child(2),
  &:nth-child(3),
  &:nth-child(4),
  &:nth-child(5),
  &:nth-child(6) {
    width: calc(90% / 5);
    max-width: calc(90% / 5);
  }
}
.container-list {
  position: relative;
  display: flex;
  flex-direction: column;
  height: calc(100% - 50px);
}
div.page-container {
  height: 100%;
  width: calc(100% - 100px);
  font-family: "Lato", sans-serif;
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  box-shadow: rgba(255, 255, 255, 0.2), rgba(255, 255, 255, 0.14),
    rgba(255, 255, 255, 0.12);
  margin: 30px 50px;
  font-size: 14px;
  .scroll {
    display: flex;
    flex-direction: column;
    overflow: hidden;
    overflow-y: auto;
    height: calc(100% - 65px);
  }
}
.checkpayment-btn {
  font-size: 10px !important;
  max-width: 155px 10px !important;
}
.info.checkpayment-btn {
  margin-right: 5px;
  &[disabled=""] {
    background: #14a2b840;
  }
}
// expansion panel section
.wrap-collabsible.selected {
  // order: -1;
  display: flex;
  flex-direction: column;
  opacity: 1;
  // margin-bottom: 25px;
  // margin-top: 25px;
  .lbl-toggle {
    background: radial-gradient(
      circle at 50% 100%,
      rgb(23 23 25 / 50%),
      rgb(8 59 100 / 61%)
    );
    //background:radial-gradient(circle at 50% 100%, #0089db9c, #0770d063);
  }
}
.wrap-collabsible.unselected {
  opacity: 0.65;
}

input[type="checkbox"] {
  display: inline-block;
}
.header .lbl-toggle {
  height: 65px;
}
.lbl-toggle {
  border-top: 1px solid rgba(255, 255, 255, 0.2);
}
.header .lbl-toggle,
#headerpanel {
  border-top: 0;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}
.lbl-toggle {
  display: block;
  padding: 1rem;
  color: white;
  background: transparent;
  position: relative;

  cursor: pointer;
  transition: all 0.25s ease-out;
  .panel-header-container {
    display: flex;
    align-items: center;
    justify-items: center;
    .flex-1 {
      display: flex;
      flex: 1;
      justify-content: center;
      align-content: center;
      align-items: center;
      text-align: center;
      span {
        white-space: nowrap;
      }
      &.nome {
        justify-content: left;
        padding-left: 20px;
        text-align: left;
      }
    }
    .flex-0 {
      display: flex;
      flex: 0px;
      justify-content: center;
      text-align: center;
      align-content: center;
      align-items: center;
    }
    .flex-0.select {
      max-width: 120px;
      min-width: 105px;
    }
  }
}

.collapsible-content {
  max-height: 0px;
  overflow: hidden;
  transition: max-height 0.25s ease-in-out;
}

.toggle:checked + .lbl-toggle + .collapsible-content {
  max-height: 100vh;
}

.collapsible-content .content-inner {
  border-bottom: 1px solid #00dbc350;
  border-top: 1px solid #00dbc350;
  padding: 0.5rem 1rem;
}
.expansion-panel.selected .arrow svg {
  transform: rotate(90deg);
  transition: all 0.2s ease-in-out;
}
.arrow {
  flex: 0 !important;
  min-width: 40px;
  max-width: 40px;
  svg {
    transform: rotate(0deg);
    transition: all 0.2s ease-out;
  }
}
.topHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
}
.table-container {
  border-radius: 5px;
  margin: 25px;
  box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
  overflow: hidden;
}
.content-table {
  border-radius: 5px;
  margin: 1px;
  background: #1a73a347;
  tr:first-child {
    background: #1a74a3;
  }
  tr th:first-child,
  tr td:first-child {
    text-align: left;
    //width: 20%;
    // max-width: 20%;
    padding-left: 30px;
  }
  tr th,
  tr td {
    text-align: center;
    padding-left: 30px;
    width: calc(100% / 6);
    max-width: calc(100% / 6);
    // border:1px solid #dc3545;
  }
  .badge-euro {
    display: inline-block;
    border: 1px solid #1f88c1;
    background-color: #0cced2c2;
    // background: transparent;
    color: white;
    min-width: 35px;
    height: 35px;
    border-radius: 35px;
    text-align: center;
    line-height: 35px;
    margin: 0 5px;
    font-weight: 600;
    padding: 0 10px;
  }
  .badge-payment {
    display: inline-block;
    border: 1px solid #db5832;
    background: transparent;
    //background-image: linear-gradient(to right, transparent, #db583235, #db583245, #db583248, #db583245, #db583235, transparent);
    color: white;
    min-width: 35px;
    height: 35px;
    border-radius: 5px;
    text-align: center;
    line-height: 35px;
    margin: 0 5px;
    font-weight: 600;
    padding: 0 10px;
    text-transform: uppercase;
    line-height: 35px;
    font-size: 12px;
    &.paid {
      border: 1px solid #1f88c1;
      //background: #8dbc68;
      background: transparent;
      //background-image: linear-gradient(to right, transparent, #1f88c135, #1f88c145, #1f88c148, #1f88c145, #1f88c135, transparent);
    }
  }
}

.dot-border {
    margin: auto;
    border-radius: 30px;
    height: 30px;
    max-height: 30px;
    min-height: 30px;
    line-height: 30px;
    min-width: 30px;
    padding: 0 0.65rem;
    border: 1px solid transparent;
    display: block;
    width: fit-content;
    transform: scale(0.85);

  &.dot-color0 {
    border-color: #ffc107;
  }
  &.dot-color1 {
    border-color: #A9DC62;
  }
  &.dot-color2 {
    border-color: #dc3545;
  }
}

.dialog-header {
  margin: -18px 0 9px 0;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  .title {
    color: #a72632;
    font-size: 16px !important;
    font-style: normal;
    font-weight: 400 !important;
    line-height: 18px !important;
    letter-spacing: 0em;
    text-align: left !important;
    margin-bottom: 18px;
  }
  .close {
    position: relative;
  }
}
th:first-child,
td:first-child {
  padding-left: 0.4rem !important;
  padding-right: 0rem !important;
}
th:last-child,
td:last-child {
  padding-right: 1.5rem !important;
}
td.chevron svg {
  transform: rotate(0deg);
  transition: all 50ms ease-in;
  &.rotate {
    transform: rotate(90deg);
    transition: all 100ms ease-in;
  }
}
.payment-alert {
  border: 1px solid #ffffff20;
  background: #a4c84d15;
  display: flex;
  align-items: center;
  padding: 0.5rem 0.5rem;
  margin: 1px;
  .payment-badge {
    background: #a4c84d55;
    width: 45px;
    height: 45px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 0.5rem;
  }
}
@media only screen and (max-width: 720px) {
  .topHeader {
    flex-direction: column;
  }
  .buttonsOnTop {
    margin-bottom: 20px;
    width: 100%;
  }
  .lbl-toggle {
    padding: 0rem 1rem;
  }
  .selected {
    margin: 0 !important;
  }
  div.expansion-panel.header.wrap-collabsible {
    display: none;
  }
  .panel-header-container {
    position: relative;
    flex-direction: column;
    div:not(.bordered):not(.bordered *) {
      display: block !important;
      width: 100%;
      max-width: 100% !important;
      position: relative;
      text-align: right !important;
      line-height: 38px;
      min-height: 38px;
      padding: 0 !important;
      word-spacing: 1px !important;
      svg {
        margin: 7px;
      }
      input.select-company {
        margin-top: 12.5px;
      }
      button {
        margin-left: auto;
        margin-right: auto;
      }
    }
    div:nth-of-type(4) {
      margin-top: 5px;
    }
    div:before {
      text-align: left;
      position: absolute;
      display: block;
      width: 50%;
      line-height: 38px;
      min-height: 38px;
    }
    div:nth-of-type(2):before {
      content: "Nome Azienda";
    }
    div:nth-of-type(3):before {
      content: "Coid";
    }
    div:nth-of-type(4):before {
      content: "Euro";
    }
    div:nth-of-type():before {
      content: "";
    }
  }
}

@media (min-width: 601px) and (max-width: 1026px) {
  .nome {
    display: -webkit-box !important;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    padding-left: 5px !important;
  }
  .panel-header-container {
    div:not(.badge):not(.select) {
      min-width: 20%;
    }
    div:nth-of-type(5),
    div:nth-of-type(4) {
      word-spacing: 300px;
    }
  }
}

#mainTable {
  th {
    &:nth-child(3),
    &:nth-child(4),
    &:nth-child(5),
    &:nth-child(6) {
      width: 100px;
      div {
        justify-content: center
      }
    }
  }
  tr {
    td {
      &:nth-child(3),
      &:nth-child(4),
      &:nth-child(5),
      &:nth-child(6) {
        text-align: center;
      }
    }
  }
}
</style>